const en = 'release' // test = 测试服， release = 正式服
export const platform = getQueryString('platform') || 'msyz' // msyz = 面试有招，szzy = 数智职翼，cqcsgl = 重庆城市管理职业学院（小管求职）
export let apiUrl = null // 网络请求域名
export let H5Url = null // 面试有招H5访问链接
if (en === 'test') {
  if (platform === 'msyz') {
    apiUrl = 'http://ms.czlks.com/v1/web'
    H5Url = 'http://h5.czlks.com/#/' // 测试服：http://h5.czlks.com
  } else if (platform === 'szzy') {
    apiUrl = 'http://det.czlks.com'
    H5Url = 'http://deh5t.czlks.com/#/' // 测试服：http://deh5t.czlks.com/#/
  }
} else if (en === 'release') {
  if (platform === 'msyz') {
    apiUrl = 'https://msapi.czlks.com/v1/web'
    H5Url = 'https://mm.czlks.com/#/' // 正式服：https://mm.czlks.com
  } else if (platform === 'szzy') {
    apiUrl = 'https://deapi.gxtizi.com'
    H5Url = 'https://szzy.gxtizi.com/digital_employment_mobilea/#/' // 正式服：https://demh.gxtizi.com https://szzy.gxtizi.com/digital_employment_mobilea/#/
  } else if (platform === 'cqcsgl') {
    apiUrl = 'https://de.cqc.edu.cn:45187'
    H5Url = 'https://de.cqc.edu.cn:45186/digital_employment_mobilea/#/' // 正式服：https://de.cqc.edu.cn:45186/digital_employment_mobilea/#/
  }
}
export const upyunUrl = 'https://msimg.czlks.com'
export const upFileYunUrl = 'https://msimg.czlks.com'
export const youPaiYunUrl = 'https://v0.api.upyun.com'

function getQueryString(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const search = window.location.hash.split('?')[1]
  if (search) {
    const r = search.match(reg)
    if (r != null) {
      sessionStorage.setItem('platform',  unescape(r[2]))
      return unescape(r[2])
    } else if (sessionStorage.getItem('platform')) {
      return sessionStorage.getItem('platform')
    } else {
      return null
    }
  } else {
    return null
  }
}
