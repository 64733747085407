const state = {
  channel: '',
  token:sessionStorage.getItem('token')|| '',
  interviewToken: null,
  userInfo: JSON.parse(sessionStorage.getItem('userInfo')) || null, // 用户信息
  resumeAnalysisUserInfo: null, // 用户上传简历解析的信息
  removerContents: null, // 清空用户数据
  routerPath: null, // 当前用户访问的路径
  currentResumeEditId: 0, // 当前简历id
  currentResumeInfo: null // 当前简历信息
}

export default state
