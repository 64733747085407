<template>
  <!-- 退出账号弹窗 -->
  <div class="onOutBox">
    <div class="onOutPageBox"></div>
    <div class="onOutPageTextBox">
      <div class="onOutPageText">退出应用</div>
      <div class="onOutUseText">是否退出应用？</div>
      <div class="onOutBootom-Btn">
        <div class="cancel" @click="cancelfn">取消</div>
        <div class="ensure" @click="onOutPageFn">确定</div>
      </div>
    </div>
  </div>
  <!-- 退出账号弹窗 -->
</template>

<script>
export default {
  methods: {
    onOutPageFn() {
      this.$emit('ensureFn')
    },
    cancelfn() {
      this.$emit('cancelfn', false)
    }
  }
}
</script>

<style lang="stylus" scoped>
.onOutBox
  top 50%
  left 50%
  position absolute
  z-index 999
  transform translate(-50%, -92%)
.onOutPageBox
  width 480px
  height 247px
  background-image url('~@/assets/image/框.png')
  background-repeat no-repeat
.onOutPageTextBox
  top 56%
  left 50%
  transform translate(-50%, -50%)
  position absolute
  width 480px
  text-align center
.onOutPageText
  font-size 20px
  font-family Source Han Sans CN
  font-weight 500
  color #01E0FB
  margin-bottom 20px
.onOutUseText
  letter-spacing 0.1em
  font-size 14px
  font-family Source Han Sans CN
  font-weight 400
  color #FFFFFF
.onOutBootom-Btn
  display flex
  justify-content center
  margin-top 38px
.cancel
  cursor pointer
  text-align center
  width 73px
  height 33px
  line-height 33px
  background #FFFFFF
  border 1px solid #CCCCCC
  border-radius 4px
  margin-right 20px
  font-size 14px
  font-family Source Han Sans CN
  font-weight 400
  color #666666
.ensure
  cursor pointer
  text-align center
  width 73px
  height 34px
  background #00E2FB
  border-radius 4px
  line-height 33px
  font-size 14px
  font-family Source Han Sans CN
  font-weight 400
  color #FFFFFF
</style>
