export const SET_CHANNEL = 'SET_CHANNEL'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_INTERVIEWTOKEN = 'SET_INTERVIEWTOKEN'
export const SET_USERINFO = 'SET_USERINFO'
export const SET_RESUMEANALYSISUSERINFO = 'SET_RESUMEANALYSISUSERINFO'
export const REMOVER_CONTENTS = "REMOVER_CONTENTS"
export const SET_ROUTER_PATH = 'SET_ROUTER_PATH'
export const SET_CURRENT_RESUME_EDIT_ID = 'SET_CURRENT_RESUME_EDIT_ID'
export const SET_CURRENT_RESUME_INFO = 'SET_CURRENT_RESUME_INFO'


