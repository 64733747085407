
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import md5 from '@/assets/js/md5'
import dataV from '@jiaminghi/data-view'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import drag from '@/utils/drag'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import VueParticles from 'vue-particles'
import '@/assets/styles/index.css'
import '@/icon/iconfont.css'
import htmlToPdf from '@/utils/htmlToPdf'
import Print from '@/utils/print.js'
import '@/styles/element-ui.css' // global css
Vue.use(htmlToPdf)
Vue.use(Print) // 打印
// 引入echarts
import echarts from '@/utils/Echarts'
//调用的时候就是 :this.$echarts
// 挂载到vue实例中
Vue.prototype.$echarts = echarts

require('./assets/js/hmac-sha256')
require('./assets/js/HmacSHA1')
require('./assets/js/enc-base64-min')
require('./assets/js/md5')

Vue.use(VueParticles)
Vue.use(dataV)
Vue.use(ElementUI, { locale }, dataV)
Vue.config.productionTip = false
Vue.prototype.$md5 = md5

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
