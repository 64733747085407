<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
    <ExitApplication v-if="popUp" @ensureFn="ensureFn" @cancelfn="cancelfn" />
  </div>
</template>

<script>
import store from '@/store'
import { mapMutations } from 'vuex'
import { platform } from '@/api/config'
import ExitApplication from '@/components/ExitApplication'

// const token1 =
//   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vZGVhcGkuY3psa3MuY29tIiwiYXVkIjoiaHR0cDovL2RlYXBpLmN6bGtzLmNvbS92MS9sb2dpbiIsImp0aSI6InR6a2oiLCJpYXQiOjE2OTU3MjU3ODIuNDQwMDEyLCJleHAiOjE2OTU3MjkzODIuNDQwMDEyLCJ1aWQiOiIxNjk1NzI1NzY0MDAwNDcyIn0.9ETNV2QJxfG9ve7XVjtgvwj-UDqTSDKuxFEUAy6c0XQ'
// if (token1) {
//   store.dispatch('getUserInfo')
//   store.commit('SET_TOKEN', token1)
//   sessionStorage.setItem('token', token1)
// }

const token = sessionStorage.getItem('token')
if (token) {
  store.commit('SET_TOKEN', token)
  store.dispatch('getUserInfo')
}

export default {
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,
      popUp: false //控制退出应用弹窗的显示
    }
  },
  created() {
    this.changeIco()
    const _this = this
    document.onkeydown = function (e) {
      if (e.keyCode === 27) _this.onOutPageFn()
    }
  },
  methods: {
    ...mapMutations({
      setRouterPath: 'SET_ROUTER_PATH'
    }),
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    // 修改Favicon的方法
    changeFavicon(link) {
      let $favicon = document.querySelector('link[rel="icon"]')
      if ($favicon !== null) {
        $favicon.href = link
      } else {
        $favicon = document.createElement("link")
        $favicon.rel = 'icon'
        $favicon.href = link
        document.head.appendChild($favicon)
      }
    },
    // 根据传递的参数修改Favicon
    changeIco() {
      let iconUrl
      if (platform === 'msyz') {
        iconUrl = `./msyz_favicon.ico`
      } else if (platform === 'szzy') {
        iconUrl = `./szzy_favicon.ico`
      }
      this.changeFavicon(iconUrl)
    },
    // 监听打开退出弹窗
    onOutPageFn() {
      // this.$confirm('是否退出应用?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning',
      //   center: true,
      //   closeOnPressEscape: false
      // }).then(() => {
      //   history.go(-history.length + 1)
      // })
      this.popUp = true
    },
    //当用户点击确定退出
    ensureFn() {
      history.go(-history.length + 1)
    },
    //当用户点击取消关闭退出应用的弹窗
    cancelfn(data) {
      this.popUp = data
    },
  },
  watch: {
    $route(to) {
      this.setRouterPath(to.path)
    }
  },
  components: {
    ExitApplication
  }
}
</script>

<style lang="stylus" scoped></style>
