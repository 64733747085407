import axios from 'axios'
import { apiUrl, youPaiYunUrl } from '@/api/config'
import store from '@/store'
import router from '../router'
import { Message } from 'element-ui'

function errorFn(error) {
  //忽略名单
  if (error.config.url.includes('interview')) {
    return Promise.reject(error)
  } else if (error.config.url.includes('resume')) {
    if (error.response.data.code === 401) {
      Message.error('用户登录过期,请重新登录！')
      router.push({ path: '/resume/login', query: { go: 1 } })
    }
  }
}
// 内部请求
export const httpRequest = axios.create({
  baseURL: apiUrl
})
// 又拍云请求
export const httpRequestUpyun = axios.create({
  baseURL: youPaiYunUrl
})
// 内部添加请求拦截器
httpRequest.interceptors.request.use(
  params => {
    if (store.state.interviewToken) {
      params.headers.Authorization = store.state.interviewToken
    } else if (store.state.token) {
      params.headers.Authorization = store.state.token
    }
    return params
  },
  error => {
    console.log(error)
    // return Promise.reject(error)
  }
)
// 内部添加响应拦截器
httpRequest.interceptors.response.use(
  response => {
    // 统一处理数据
    return response.data
  },
  error => {
    errorFn(error)
  }
)
// 又拍云添加请求拦截器
httpRequestUpyun.interceptors.request.use(
  params => {
    return params
  },
  error => {
    return Promise.reject(error)
  }
)
// 又拍云添加响应拦截器
httpRequestUpyun.interceptors.response.use(
  res => {
    return res.data
  },
  error => {
    return Promise.reject(error)
  }
)
